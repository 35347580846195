<template>
  <GoogleMap 
    :api-key="googleMapsApiKey"
    style="width: 100%; height: 500px; border-radius: 20px" 
    :center="positionHandler"
    :zoom="15"
    @click="updateCurrentLocation"
    >
      <Marker 
        v-if="showMarker"
        :options="markerOptions" 
      />
  </GoogleMap>
</template>

<script>
import { mapGetters } from 'vuex';

import { defineComponent } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

export default defineComponent({
  components: { GoogleMap, Marker },

  props: {
    initialLatitude:  { type: Number, required: true },
    initialLongitude: { type: Number, required: true }
  },
  
  data() {
    return {
      position: undefined
    }
  },

  computed: {
    ...mapGetters('account', [
      'googleMapsApiKey'
    ]),

    showMarker() {
      return this.initialLatitude !== 0.0000000 || this.initialLongitude !== 0.000000
    },
    
    positionHandler() {
      return this.position !== undefined
      ? this.position
      : {
          lat: this.initialLatitude,
          lng: this.initialLongitude
        }  
    },

    markerOptions() {
      return { 
        position: this.positionHandler
      }
    }
  },

  methods: {
    updateCurrentLocation(event) {
      this.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      this.$emit('update:current-position', {
        latitude: this.positionHandler.lat,
        longitude: this.positionHandler.lng
      })
    }
  },

  emits: [ 'update:current-position' ]
});
</script>